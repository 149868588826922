import {
  useFormFieldGroup,
  useFormSection
} from "@/components/FormBuilder/Helpers";
import { FormBlock } from "../../components/FormBuilder/types";
import { useTextField } from "../../components/FormBuilder/Helpers/fields/useTextField";
import { useSelectField } from "../../components/FormBuilder/Helpers/fields/useSelectField";
import { formUsage } from "@/helpers/selectOptions";
import { useField } from "../../components/FormBuilder/Helpers/fields/useField";
export const endorsementsForm: FormBlock[] = [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useTextField({
            key: "formName",
            label: "Form Name",
            placeholder: "Form name",
            required: true
          }),
          useTextField({
            key: "formNumber",
            label: "Form Number",
            placeholder: "Form number",
            required: true
          }),
          useTextField({
            key: "formCode",
            label: "Form Code",
            placeholder: "Form Code",
            required: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "formLabel",
            label: "Form Label",
            placeholder: "Form label",
            required: false
          }),
          useField({
            key: "companies",
            label: "Select Companies",
            type: "select",
            required: true,
            multiple: true
          }),
          useField({
            key: "formTypes",
            label: "Select Form Types",
            type: "select",
            required: true,
            multiple: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useSelectField({
            key: "formUsage",
            label: "Form Usage",
            required: true,
            options: formUsage,
            filterable: false
          }),
          useField({
            key: "appliesTo",
            label: "Applies To",
            type: "select",
            required: true,
            multiple: true
          })
        ],
        { layout: "2-col" }
      )
    ],
    {
      title: "Attention",
      description:
        "Please send lead programmer form code for this endorsement so it can be added to sending xml"
    }
  )
];
